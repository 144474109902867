<template>
  <section class="page-content">
    <p class="description">
      W zakładce Wizytówka w Wirtualnym JOBICONIE wypełnij wszystkie pola
      informacjami o firmie, a my przygotujemy wirtualną wizytówkę, którą
      będziesz promował na ekranach dotykowych. Jeżeli posiadasz profil
      pracodawcy lub stronę kariery, skorzystaj z gotowych treści.
    </p>
    <p class="description">
      <strong>
        Jeśli masz problem z wgraniem poprawnej wizytówki skontaktuj się z nami
        mailowo
        <a class="important-info" :href="`mailto:${companyGuardian.email}`">{{
          companyGuardian.email
        }}</a>
      </strong>
    </p>
    <download-data page-type="_bc" v-if="!disabled" @success="updateDataKey" />
    <form v-if="this.namedDetails !== undefined" class="main-form">
      <h2>Wizytówka firmy:</h2>
      <div v-show="formData._bc_option === 'first-bc-option'">
        <v-checkbox-slider
          id="_bc_english"
          name="_bc_english"
          label="Włącz nagłówki w wersji angielskiej"
          @auto-save="sendData(false)"
          :disabled="disabled"
          v-model="formData._bc_english"
        />
        <span class="characters-count">
          Pozostała łączna liczba znaków:
          <span class="blue-text">
            {{ charactersLeft }}
          </span>
        </span>
        <div class="description">
          <p class="margin-bt-3">
            W celu zoptymalizowania dodawanej grafiki, zalecamy skorzystanie z
            intuicyjnego narzędzia dostępnego pod adresem
            <strong>
              <a
                href="https://www.easy-resize.com/pl/"
                target="_blank"
                rel="nofollow noreferrer"
              >
                www.easy-resize.com
              </a>
            </strong>
          </p>
          <p class="informations">
            Grafika zostanie umieszczona w nagłówku wizytówki Twojej firmy.
            Prosimy o załadowanie grafiki w formacie: jpg, jpeg lub png.
            Maksymalna waga pliku to 5 MB. Grafika powinna być w rozmiarze
            1200px x 325px.<br />
            <strong>
              Skontaktuj się z nami jeśli masz problem z wgraniem grafiki w
              poprawnym formacie.
            </strong>
          </p>
        </div>
        <v-upload
          id="_bc_header_image"
          name="_bc_header_image"
          label="Załaduj grafikę wizytówki"
          label-alt="Zmień nagłówek wizytówki"
          file-type="raster"
          :confirmMessage="'Potwierdź dodanie grafiki'"
          :edition="activeEdition"
          :company="companyData.id"
          v-model="formData._bc_header_image"
          :detailId="getDetailId('_bc_header_image')"
          :disabled="disabled"
        />
        <v-textarea
          id="_bc_about_company"
          :key="`_bc_about_company_${dataKeyIndex}`"
          name="_bc_about_company"
          :label="formData._bc_english ? headers.en.about : headers.pl.about"
          :max-characters="maxCharacters"
          :used-characters="charactersLeft"
          @content-length="assignContentLength($event)"
          :default-value="
            this.namedDetails[this.activeEdition + `_bc_about_company`]
          "
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData._bc_about_company"
        />
        <v-textarea
          id="_bc_who_we_are_looking_for"
          :key="`_bc_who_we_are_looking_for_${dataKeyIndex}`"
          name="_bc_who_we_are_looking_for"
          :max-characters="maxCharacters"
          :used-characters="charactersLeft"
          :label="formData._bc_english ? headers.en.who : headers.pl.who"
          @content-length="assignContentLength($event)"
          :default-value="
            this.namedDetails[this.activeEdition + `_bc_who_we_are_looking_for`]
          "
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData._bc_who_we_are_looking_for"
        />
        <v-textarea
          id="_bc_recrutation_process"
          :key="`_bc_recrutation_process_${dataKeyIndex}`"
          name="_bc_recrutation_process"
          :label="
            formData._bc_english
              ? headers.en.recrutation
              : headers.pl.recrutation
          "
          :max-characters="maxCharacters"
          :used-characters="charactersLeft"
          @content-length="assignContentLength($event)"
          :default-value="
            this.namedDetails[this.activeEdition + `_bc_recrutation_process`]
          "
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData._bc_recrutation_process"
        />
        <v-textarea
          id="_bc_why_us"
          :key="`_bc_why_us_${dataKeyIndex}`"
          name="_bc_why_us"
          :label="formData._bc_english ? headers.en.why : headers.pl.why"
          :max-characters="maxCharacters"
          :used-characters="charactersLeft"
          @content-length="assignContentLength($event)"
          :default-value="this.namedDetails[this.activeEdition + `_bc_why_us`]"
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData._bc_why_us"
        />
        <v-textarea
          id="_bc_www"
          :key="`_bc_www_${dataKeyIndex}`"
          name="_bc_www"
          :label="formData._bc_english ? headers.en.www : headers.pl.www"
          :max-characters="maxCharacters"
          :used-characters="charactersLeft"
          @content-length="assignContentLength($event)"
          :default-value="this.namedDetails[this.activeEdition + `_bc_www`]"
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData._bc_www"
        />
      </div>
      <div v-if="!disabled" class="send-btn-cont">
        <main-btn
          :content="'Zapisz wersję roboczą'"
          :tooltip="'Wersja robocza umożliwia Ci naniesienie zmian i edytowanie tej zakładki. Pamiętaj aby jak najszybciej zapisać wersję ostateczną materiałów, która zostanie przesłana do organizatora wydarzenia.'"
          :submit="true"
          :saving="loading"
          :gray="true"
          @click="sendData(false)"
        />
        <main-btn
          :disabled="charactersLeft < 0"
          :content="saveFinalDataText"
          :tooltip="'Wersja ostateczna to finalna informacja jaka zostanie przesłana do Pracuj.pl na potrzeby organizacji wydarzenia'"
          :submit="true"
          :saving="loading"
          @click="
            sendData(true, `_bc_disabled`, ignoredKeys[formData._bc_option])
          "
        />
      </div>
      <div v-else>
        <p>
          <strong
            >Ustawienia zakładki zostały przekazane do Pracuj.pl. Jeśli chcesz
            dokonać zmian skontaktuj się pod adresem
            <a
              class="important-info"
              :href="`mailto:${companyGuardian.email}`"
              >{{ companyGuardian.email }}</a
            ></strong
          >
        </p>
      </div>
      <p class="text-center margin-tp-1" v-show="message">
        <strong :class="`message--${messageType}`">{{ message }}</strong>
      </p>
    </form>
  </section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import sendDataAndDispatch from "@/mixins/sendDataAndDispatch.js";
import assignDataFromDatabase from "@/mixins/assignDataFromDatabase.js";
import commonContentLengthForTextarea from "@/mixins/commonContentLengthForTextarea.js";
import getDetailId from "@/mixins/getDetailId.js";

export default Vue.extend({
  mixins: [
    sendDataAndDispatch,
    assignDataFromDatabase,
    commonContentLengthForTextarea,
    getDetailId,
  ],
  data() {
    return {
      loading: false,
      message: "",
      messageType: "ok",
      disabled: false,
      dataKeyIndex: 0,
      headers: {
        pl: {
          about: "O firmie (wymagane)*",
          who: "Kogo szukamy?",
          recrutation: "Jak przebiega proces rekrutacji?",
          why: "Dlaczego warto u nas pracować?",
          www: "Adres strony www",
        },
        en: {
          about: "About us (required)*",
          who: "Who are we looking for?",
          recrutation: "Recruitment process",
          why: "What you may expect from us?",
          www: "Our career website",
        },
      },
      formData: {
        _bc_english: false,
        _bc_about_company: "",
        _bc_who_we_are_looking_for: "",
        _bc_recrutation_process: "",
        _bc_why_us: "",
        _bc_www: "",
        _bc_disabled: false,
        _bc_option: "first-bc-option",
        _bc_pracuj: "",
        _bc_image: "",
        _bc_header_image: "",
      },
      ignoredKeys: {
        "first-bc-option": [
          "_bc_pracuj",
          "_bc_english",
          "_bc_who_we_are_looking_for",
          "_bc_recrutation_process",
          "_bc_why_us",
          "_bc_www",
          "_bc_image",
          "_bc_header_image",
        ],
        "second-bc-option": [
          "_bc_english",
          "_bc_about_company",
          "_bc_who_we_are_looking_for",
          "_bc_recrutation_process",
          "_bc_why_us",
          "_bc_www",
          "_bc_image",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["companyData", "activeEditionOpened", "companyGuardian"]),
  },
  methods: {
    updateDataKey() {
      this.dataKeyIndex += 1;
    },
    clearInput(value) {
      this.formData[value] = "";
    },
    checkIfPageIsDisabled() {
      this.disabled = this.formData._bc_disabled;

      if (!this.activeEditionOpened) {
        this.disabled = true;
      }
    },
    prepareData() {
      this.assingData(`_bc_english`, false, "boolean");
      this.assingData(`_bc_about_company`, "");
      this.assingData(`_bc_who_we_are_looking_for`, "");
      this.assingData(`_bc_recrutation_process`, "");
      this.assingData(`_bc_why_us`, "");
      this.assingData(`_bc_www`, "");
      this.assingData(`_bc_option`, "first-bc-option");
      this.assingData(`_bc_pracuj`, "");
      this.assingData(`_bc_image`, "");
      this.assingData(`_bc_disabled`, false, "boolean");
      this.assingData(`_bc_header_image`, "");
    },
  },
  mounted() {
    this.prepareData();
    this.checkIfPageIsDisabled();
  },
  watch: {
    companyData() {
      this.prepareData();
      this.checkIfPageIsDisabled();
    },
    activeEditionOpened() {
      this.checkIfPageIsDisabled();
    },
  },
});
</script>

<style lang="scss" scoped>
.business-card {
  &__img {
    @include tablet {
      max-width: 400px;
    }
  }
}
</style>
